.top-nav{
    background-color: $primary-color !important;
}

.navbar-nav .nav-link{
    margin-left: 5px;
    margin-right: 5px;
    font-size: .8em;
    font-weight: bold;
    
    // a{
        text-transform: uppercase;
    // }
    
}

.dropdown-menu{
    a{
        font-size: .8rem;
    }
}

.text-wrap{
    white-space: normal;
}

.megamenu-li {
    position: static;
}

.megamenu {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    z-index: 10000;

    h5{
        font-size: 1rem;
        white-space: normal;
        font-weight: 600;
    }

    a{
        font-size: .8rem;
    }
}