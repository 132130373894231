footer{
    background-color: #01546C;
    p{
        font-size: .7rem;
    }
    .list-group-item{
        border: 0px;
        font-size: .7rem;
        padding-left: 0px;
        .far, .fas{
            font-size: 1.2rem;
        }
        .fab{
            font-size: 1.8rem;
            color: white;
        }
    }
}