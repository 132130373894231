@import "../node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
  "primary": #007DA2,
  "secondary": #FF6E00
);

$primary-color: #007DA2;
$secondary-color: #FF6E00;
$third-color: #E5E5E5;
$fourth-color: #F8F8F8;
$fifth-color: #01546C;
$sixth-color: #D13610;
$seventh-color: #E86C42;

.bg-primary{
    color: $primary-color !important;
}

.bg-light-grey{
  background-color: $fourth-color !important;
}
.text-primary{
    color: $primary-color !important;
}

.text-secondary{
  color: $secondary-color !important;
}

.text-orange2{
  color: $seventh-color;
}

@import "partials/home";
@import "partials/footer";
@import "partials/nav";
@import "partials/patient_conditions"