.page-home{

    .logo-img img{
        position: absolute;
        height: 170px;
        left: 110px;
        top: 100px;
        z-index: 9999;
    }

    .homepage-hero{
        h1{
            font-size: 4.2rem;
        }
        background-image: url("../img/homepage-hero.jpg");
        .homepage-drjaudy img{
            height: 27rem;
        }
    }

    .subtitle{
        font-size: .9em;
    }

    .card{
        .card-title{
            font-size: .8rem;
        }
        .card-text{
            font-size: .9rem;
        }
        
    }

    .play-intro-video{
        button{
            font-size: 1.2rem;
        }
        i{
            font-size: 1.3rem;
        }
        img{
            width: 100% !important;
        }
    }

    .faqs{
        .card-title{
            font-weight: bold;
            color: $primary-color;
        }
    }

    .featured-testimonials{
        background-image: url("../img/bg2.jpg");
        button{
            background-color: $primary-color;
        }
    }

    .top10-patient-conditions{
        .btn{
            background-color: $primary-color;
        }
    }

    .schedule-your-appointment-today{
        background-color: $fourth-color;
        h2{
            font-size: 3rem;
        }
        button{
            font-size: 1.6rem;
            font-weight: 600;
            background-color: $primary-color;
        }
    }
    
    .treatment-center{
        h3{
            margin-bottom: 0px;
        }
        small{
            font-size: .6rem;
        }
    }
}